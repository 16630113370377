import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import "../assets/index.css"

const headerColor = ({ url }) => {
    let color = '#000000';
    const urlArray = url.split('/').filter(item => item !== '');
    const strippedUrl = urlArray[urlArray.length -1];
    if (!url) {
        return color = '#FFFFFF';
    }
    switch (strippedUrl) {
        case 'about':
            color = '#4157FE';
            break;
        case 'movieposters':
            color = '#4157FE';
            break;
        case 'asutra':
            color = '#FFFFFF';
            break;
        case 'metta':
            color = '#000000';
            break;
        case 'nutririch':
            color = '#FFFFFF';
            break;
        case 'popchart':
            color = '#FFFFFF';
            break;
        case 'posterjack':
            color = '#000000';
            break;
        case 'calm-blanket':
            color = '#FFFFFF';
            break;
        case 'juneshine':
            color = '#000000';
            break;
        case 'bill-blass':
            color = '#000000';
            break;
        default:
            color = '#FFFFFF';
            break;
        case '404.js':
            color = '#4157FE';
            break;
    }
    return color;
};

const Layout = ({ children, location, ...props }) => {
    let hasFooter = true;
    if ({ url: location.pathname }.url.indexOf('/site/') >= 0) {
        hasFooter = false;
    }
    return (
        <main>
            <Header textColor={headerColor({ url: location.pathname })}/>
            {children}
            {hasFooter && <Footer/>}
        </main>
    )
}

export default Layout