import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import posed from "react-pose"
/* eslint-disable no-unused-vars */

const HeaderDiv = styled.div`
    position: absolute;
    top: 0;
    z-index: 999;
    width: 100%;
    padding: 40px 0;

    @media only screen and (min-width: 1024px) {
        padding: 69px 0;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 30px;

    @media only screen and (min-width: 750px) {
        padding: 0 20px;
    }
`;

const LogoDiv = styled.div`
    display: inline-block;
    vertical-align: middle;

    svg {
        width: 139px;

        @media only screen and (min-width: 1024px) {
            width: auto;
        }
    }
`;

const StyledLink = styled(Link)`
    display: block;
    line-height: 0;
`;

const MenuDiv = styled.div`
    display: inline-block;
    vertical-align: middle;
    margin-top: 4px;
    float: right;
`;

const MenuIconDiv = styled.div`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    user-select: none;
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }
`;

const MenuUl = styled.ul`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    margin: 0;
    text-align: center;
`;

const MenuLi = styled.li`
    margin: 40px 0;
    user-select: none;

    @media only screen and (min-width: 1024px) {
        display: inline-block;
        margin: 0 25px;
    }

    @media only screen and (min-width: 1200px) {
        margin: 0 37px;
    }
`;

const StyledMenuLink = styled(Link)`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    text-decoration: none;
    color: #FFFFFF;
    font-family: 'Graphik Bold', Helvetica, Arial, sans-serif;
    font-size: 35px;
    line-height: 35px;
    letter-spacing: -1px;

    @media only screen and (min-width: 750px) {
        font-size: 40px;
        line-height: 0;
    }

    &:hover {
        opacity: 0.5;
    }
`;

const MenuFade = posed(styled.div`
    position: absolute;
    display: ${({ menuOpen }) => menuOpen ? 'block' : 'none'};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 60px 0;
    z-index: 990;
    background: #070015;
`)({
    init: { opacity: 0 },
    enter: {
        applyAtStart: { display: 'block' },
        opacity: 1,
        transition: {
            opacity: {
                ease: 'easeIn',
                duration: 200
            }
        }
    },
    exit: {
        applyAtEnd: { display: 'none' },
        opacity: 0,
        transition: {
            opacity: {
                ease: 'easeOut',
                duration: 200
            }
        }
    }
});

const logoSvg = ({ textColor }) => (
    <svg width="209" height="48" viewBox="0 0 209 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={{ fill: textColor }} d="M18.0267 5H0V47.1154H12.6695V37.4231H17.5966C29.9924 37.4231 37.3048 31.3462 37.3048 21.0769C37.3048 10.9615 30.1097 5 18.0267 5ZM17.8703 26.8077H12.6695V15.6154H17.9094C22.0153 15.6154 24.4788 17.6538 24.4788 21.0769C24.4397 24.6538 21.9762 26.8077 17.8703 26.8077ZM55.918 13.8846C45.5165 13.8846 37.5394 20.3462 37.5394 30.8462V31.3462C37.5394 42 45.5947 47.9615 55.8789 47.9615C66.1632 47.9615 74.2185 41.6923 74.2185 31.0385V30.5385C74.1794 20.1154 66.1632 13.8846 55.918 13.8846ZM61.5881 31.1154C61.5881 36.3846 59.7111 39 55.918 39C52.0077 39 50.0525 36.3462 50.0525 31V30.5C50.0525 25.3462 52.125 22.8846 55.918 22.8846C59.7502 22.8846 61.5881 25.5385 61.5881 30.6923V31.1154ZM76.3692 47.1538H88.7259V14.7308H76.3692V47.1538ZM82.5475 0C78.7154 0 75.8217 2.53846 75.8217 6.19231C75.8217 9.73077 78.7154 12.3077 82.5475 12.3077C86.3406 12.3077 89.2342 9.69231 89.2342 6.19231C89.2342 2.53846 86.3406 0 82.5475 0ZM114.691 13.8846C109.138 13.8846 105.619 16.5 103.781 19.9615V14.7308H91.3849V47.1154H103.781V29.2308C103.781 25.3462 105.619 23.5769 108.708 23.5769C111.68 23.5769 112.853 25.2308 112.853 28.5385V47.1154H125.249V25.8462C125.288 17.7692 120.947 13.8846 114.691 13.8846ZM148.906 14.7308H143.119V8.11539H130.723V14.7308H126.265V22.9231H130.723V47.1538H143.119V22.9231H148.906V14.7308ZM167.05 39.6538C163.531 39.6538 161.185 37.6154 160.872 33.6538H183.747V30.6923C183.747 19.0385 175.888 13.8462 166.62 13.8462C156.531 13.8462 148.359 20.3077 148.359 30.8846V31.3846C148.359 42.1154 156.101 48 166.62 48C177.139 48 182.809 43.7308 183.826 36.2308H172.251C171.743 38.1154 170.57 39.6538 167.05 39.6538ZM166.581 21.6923C169.905 21.6923 171.547 23.8077 171.743 27.2692H160.911C161.419 23.5385 163.57 21.6923 166.581 21.6923ZM197.903 21.3077V14.7308H185.507V47.1154H197.903V32.2308C197.903 27.5385 201.618 25.3077 208.5 25.7308V14.3077C203.495 14.2308 199.975 16.3077 197.903 21.3077Z" fill="#FFFFFF"/>
    </svg>
);

const closeMenuSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#FFFFFF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 5L5 11" stroke="#FFFFFF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5 5L11 11" stroke="#FFFFFF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const openMenuSvg = ({ textColor}) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path style={{ stroke: textColor }} d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="#FFFFFF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const lockMenu = ({ targetElement, bodyScrollPos }) => {
    targetElement.style.position = 'fixed';
    targetElement.style.height = '100%';
    targetElement.style.width = '100%';
    targetElement.style.top = `-${bodyScrollPos}px`
};

const unLockMenu = ({ targetElement, bodyScrollPos, documentElement }) => {
    targetElement.style.removeProperty('position');
    targetElement.style.removeProperty('top');
    targetElement.style.removeProperty('height');
    targetElement.style.removeProperty('width');
    documentElement.scrollTop = targetElement.scrollTop = bodyScrollPos;
};

const Header = ({ textColor }) => {
    const [menuOpen, setMenuOpen] = useState(false);

    const menuButtonOpen = () => setMenuOpen(true);

    const menuButtonClose = () => setMenuOpen(false);

    let innerHeight = 0;

    const escMenu = (event) => {
        if (menuOpen && event.keyCode === 27) {
            menuButtonClose();
        }
    }

    useEffect(() => {
        if(document){
            let targetElement = document.getElementsByTagName('body')[0];
            let documentElement = document.documentElement;
            let bodyScrollPos = targetElement.scrollTop || documentElement.scrollTop || 0;
            if (menuOpen) {
                lockMenu({ targetElement, bodyScrollPos });
            } else {
                unLockMenu({ targetElement, bodyScrollPos, documentElement })
            }
            document.addEventListener('keydown', escMenu);
        }
        innerHeight = window.innerHeight;
        return () => {
            document.removeEventListener('keydown', escMenu);
        }
    }, [menuOpen]);

    return (
        <>
            <HeaderDiv>
                <WrapperDiv>
                    <LogoDiv>
                        <StyledLink
                            to="/"
                            onClick={menuButtonClose}
                        >
                            {menuOpen ? logoSvg({ textColor: '#FFFFFF' }) : logoSvg({ textColor })}
                        </StyledLink>
                    </LogoDiv>
                    {/* <MenuDiv>
                            <MenuIconDiv
                                onClick={menuOpen ? menuButtonClose : menuButtonOpen}
                                menuOpen={menuOpen}
                            >
                                {menuOpen ? closeMenuSvg() : openMenuSvg({ textColor })}
                            </MenuIconDiv>
                    </MenuDiv> */}
                </WrapperDiv>
            </HeaderDiv>
            {/* <MenuFade
                key={'menu_fade'}
                height={innerHeight}
                pose={menuOpen ? 'enter' : 'exit'}
                menuOpen={menuOpen}
            >
                <MenuUl>
                    <MenuLi>
                        <StyledMenuLink
                            to="/#work"
                            onClick={menuButtonClose}
                        >
                            Work
                        </StyledMenuLink>
                    </MenuLi>
                    <MenuLi>
                        <StyledMenuLink
                            to="/what-we-do"
                            onClick={menuButtonClose}
                        >
                            What we do
                        </StyledMenuLink>
                    </MenuLi>
                    <MenuLi>
                        <StyledMenuLink
                            to="/who-we-are"
                            onClick={menuButtonClose}
                        >
                            Who we are
                        </StyledMenuLink>
                    </MenuLi>
                    <MenuLi>
                        <StyledMenuLink
                            to="/"
                            onClick={menuButtonClose}
                        >
                            Work with us
                        </StyledMenuLink>
                    </MenuLi>
                </MenuUl>
            </MenuFade> */}
        </>
    );
}

export default Header