import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
/* eslint-disable jsx-a11y/accessible-emoji */

const SiteFooter = styled.footer`
    background: #000000;
    padding: 58px 0;

    @media only screen and (min-width: 750px) {
        padding: 44px 0 39px;
    }
`;

const WrapperDiv = styled.div`
    position: relative;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 44px;

    @media only screen and (min-width: 750px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }
`;

const SiteFooterHeadline = styled.h2`
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1.75px;
    color: #FFFFFF;
    margin: 0 0 32px;
    max-width: 280px;

    @media only screen and (min-width: 750px) {
        margin: 0;
    }

    @media only screen and (min-width: 1024px) {
        width: 45%;
        max-width: none;
    }
`;

const EmojiFire = styled.span`
    display: inline-block;
    vertical-align: middle;
    font-size: 32px;
`;

const SiteFooterMenu = styled.ul`
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -1px;
    margin: 0;
    list-style-type: none;

    @media only screen and (min-width: 750px) {
        width: 85%;
        text-align: right;
        margin-top: -1px;
    }

    @media only screen and (min-width: 1024px) {
        width: 55%;
    }
`;

const SiteFooterMenuItem = styled.li`
    display: block;
    margin: 0 0 24px;

    @media only screen and (min-width: 750px) {
        margin: 0 15px 0 0;
        display: inline-block;
        font-size: 15px;
    }

    @media only screen and (min-width: 900px) {
        margin: 0 30px 0 0;
        font-size: 16px;
    }

    &:last-child {
        margin: 0;
    }
`;

const SiteFooterLink = styled.a`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    text-decoration: none;
    color: #FFFFFF;

    &:hover {
        opacity: 0.7;
    }
`;

const SiteFooterInternalLink = styled(Link)`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    text-decoration: none;
    color: #FFFFFF;

    &:hover {
        opacity: 0.7;
    }
`;

const SiteFooterIntercomLink = styled.span`
    transition: opacity 0.2s cubic-bezier(0.46, 0.01, 0.32, 1);
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`;

const SiteFooterCopyright = styled.span`
    color: #FFFFFF;
    padding: 6px 0;
`;

const Footer = () => {
    return (
        <SiteFooter>
            <WrapperDiv>
                <SiteFooterHeadline>Let us make you look good. <EmojiFire role="img" aria-label="Fire Emoji">🔥</EmojiFire></SiteFooterHeadline>
                <SiteFooterMenu>
                    <SiteFooterMenuItem>
                        <SiteFooterLink href="https://twitter.com/pointercreative" target="_blank">Twitter</SiteFooterLink>
                    </SiteFooterMenuItem>
                    <SiteFooterMenuItem>
                        <SiteFooterLink href="https://www.instagram.com/pointercreative" target="_blank">Instagram</SiteFooterLink>
                    </SiteFooterMenuItem>
                    <SiteFooterMenuItem>
                        <SiteFooterInternalLink to="/about">About</SiteFooterInternalLink>
                    </SiteFooterMenuItem>
                    <SiteFooterMenuItem>
                        <SiteFooterLink href="https://pointer.humi.ca/job-board" target="_blank">Careers</SiteFooterLink>
                    </SiteFooterMenuItem>
                    <SiteFooterMenuItem>
                        <SiteFooterIntercomLink className="intercom-toggle footer__SiteFooterIntercomLink-r54yyk-7">Contact</SiteFooterIntercomLink>
                    </SiteFooterMenuItem>
                    <SiteFooterMenuItem>
                        <SiteFooterCopyright>&copy; Pointer {new Date().getFullYear()}</SiteFooterCopyright>
                    </SiteFooterMenuItem>
                </SiteFooterMenu>
            </WrapperDiv>
        </SiteFooter>
    )
}

export default Footer